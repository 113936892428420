<script>
import Layout from '../layouts/auth';
import { api } from '@/state/services';
import { mapState, mapActions } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';
import axios from 'axios'
import Languages from '@/components/widgets/languages';
import { checkUsername, checkCpf } from '@/state/validations/register';
import Password from '@/components/widgets/input-password';

export default {
  locales: {
    pt: {
      'Choose your package': 'Escolha seu pacote',
      'Congratulations!': 'Parabéns!',
      'Your account has been successfully created.': 'Sua conta foi criada com sucesso.',
      'Sponsor': 'Patrocinador',
      'Sponsor is required.': 'Patrocinador é obrigatório.',
      'Ambassador': 'Embaixador',
      'Ambassador is required.': 'Embaixador é obrigatório.',

      'Name': 'Nome',
      'Name is required.': 'Nome é obrigatório.',
      'Taxid': 'CPF',
      'Taxid is required.': 'CPF é obrigatório.',
      'E-mail': 'E-mail',
      'E-mail is required.': 'E-mail é obrigatório.',
      'This value should be a valid e-mail.': 'Este valor deve ser um e-mail válido.',
      'Cellphone': 'Celular',
      'Cellphone is required.': 'Celular é obrigatório.',

      'Zipcode': 'Cep',
      'Zipcode is required.': 'Cep é obrigatório.',
      'Address': 'Endereço',
      'Address is required.': 'Endereço é obrigatório.',
      'Number': 'Número',
      'Number is required.': 'Número é obrigatório.',
      'Complement': 'Complemento',
      'Complement is required.': 'Complemento é obrigatório.',
      'District': 'Bairro',
      'District is required.': 'Bairro é obrigatório.',
      'City': 'Cidade',
      'City is required.': 'Cidade é obrigatório.',
      'State': 'Estado',
      'State is required.': 'Estado é obrigatório.',

      'Username/Nickname': 'Usuário/Apelido',
      'Username is required.': 'Usuário é obrigatório.',
      'Password': 'Senha',
      'Register': 'Cadastrar',
      'Click here to login': 'Clique aqui para fazer o login',
      'Already have an account?': 'Já possui uma conta?',
      'Login': 'Login',

      'The sponsor was not found.': 'O patrocinador não foi encontrado.',
      'The username is already in use.': 'O usuário já está em uso.',
      'Fill in all required fields.': 'Preencha todos os campos obrigatórios.',

      'Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed.': 'Digite um nome de usuário válido! Não é permitido palavras como gmail, hotmail, yahoo ou outros serviços de e-mail.',

      'The CPF entered is invalid.': 'O CPF digitado é inválido.',
      'The CPF is incorrect or is already being used.': 'O CPF digitado é inválido ou já está sendo utilizado.',
    },
    es: {
      'Choose your package': 'Elige tu paquete',
      'Congratulations!': '¡Felicidades!',
      'Your account has been successfully created.': 'Tu cuenta ha sido creada satisfactoriamente.',
      'Sponsor': 'Patrocinador',
      'Sponsor is required.': 'Se requiere patrocinador.',
      'Ambassador': 'Embaixador',
      'Ambassador is required': 'Embaixador é obrigatório',

      'Name': 'Nombre',
      'Name is required.': 'Se requiere el nombre.',
      'Taxid': 'CPF',
      'Taxid is required.': 'Se requiere taxid.',
      'E-mail': 'E-mail',
      'E-mail is required.': 'Se requiere correo electrónico.',
      'This value should be a valid e-mail.': 'Este valor debe ser un correo electrónico válido.',
      'Cellphone': 'Celular',
      'Cellphone is required.': 'Se requiere celular.',

      'Zipcode': 'Cep',
      'Zipcode is required.': 'Cep é obrigatório.',
      'Address': 'Endereço',
      'Address is required.': 'Endereço é obrigatório.',
      'Number': 'Número',
      'Number is required.': 'Número é obrigatório.',
      'Complement': 'Complemento',
      'Complement is required.': 'Complemento é obrigatório.',
      'District': 'Bairro',
      'District is required.': 'Bairro é obrigatório.',
      'City': 'Cidade',
      'City is required.': 'Cidade é obrigatório.',
      'State': 'Estado',
      'State is required.': 'Estado é obrigatório.',

      'Username/Nickname': 'Nombre de Usuario/Apodo',
      'Username is required.': 'Se requiere nombre de usuario.',
      'Password': 'Contraseña',
      'Register': 'Registro',
      'Click here to login': 'Haga clic aquí para ingresar',
      'Already have an account?': '¿Ya tienes una cuenta?',
      'Login': 'Iniciar Sesión',

      'The sponsor was not found.': 'El patrocinador no fue encontrado.',
      'The username is already in use.': 'El nombre de usuario ya está en uso.',
      'Fill in all required fields.': 'Rellene todos los campos obligatorios.',

      'Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed.': 'Digite um nome de usuário válido! Não é permitido palavras como gmail, hotmail, yahoo ou outros serviços de e-mail.',

      'The CPF entered is invalid.': 'El CPF ingresado no es válido.',
      'The CPF is incorrect or is already being used.': 'O CPF digitado é inválido ou já está sendo utilizado.',
    }
  },
  components: { Layout, Languages, Password },
  data() {
    return {
      loading: {
        register: false,
      },
      success: false,
      country: {
        abbr: "BR",
        code: "55",
        name: "Brazil",
        mask: "+55.###############",
      },
      countries: {},
      sponsor: {
        status: false,
        name: '',
        username: '',
        avatar: '',
      },
      user: {
        country: "",
        sponsor: "",
        sponsored: false,
        name: "",
        birthday: "",
        taxid: "",
        email: "",
        cellphone: "",
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
        username: "",
        password: "",
        terms: {
          geral: false,
          auction: false,
          bonus: false,
          condition: false,
          policy: false,
        }
      },
      entity: {
        name: '',
        url: '',
        description: '',
        site: '',
        email: '',
        telephone: '',
        logo: '',
      },
      entities: null,
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  validations: {
    user: {
      country: { },
      sponsor: { },
      name: { required },
      birthday: { required },
      taxid: { required, checkCpf },
      email: { required, email },
      cellphone: { required },
      zipcode: { required },
      address: { required },
      number: { required },
      complement: { },
      district: { required },
      city: { required },
      state: { required },
      username: { required, checkUsername },
      password: { required, minLength: minLength(6) },
      terms: {
        geral: { required },
        auction: { required },
        bonus: { required },
        condition: { required },
        policy: { required },
      }
    }
  },
  methods: {
    ...mapActions("account", ["register"]),
    getCountries() {
      api
        .get('country')
        .then(response => {
          if (response.data.status=='success') {
            this.countries = response.data.list
          }
        })
    },
    getEntities() {
      api
        .get('entity/sponsored')
        .then(response => {
          if (response.data.status=='success') {
            this.entities = response.data.list
          }
        })
    },
    getSponsor() {
      if (this.user.sponsor != this.sponsor.username) {
        this.sponsor.name = ''
        this.sponsor.username = this.user.sponsor
        this.sponsor.avatar = ''

        api
          .post('user/sponsor', {
            username: this.user.sponsor
          })
          .then(response => {
            if (response.data.status=='error') {
              this.sponsor.status = false
              this.sponsor.name = ''
              this.sponsor.avatar = ''

              this.entity.name = ''
              this.entity.url = ''
              this.entity.description = ''
              this.entity.site = ''
              this.entity.email = ''
              this.entity.telephone = ''
              this.entity.logo = ''

              this.$router.push('/register')
            } else {
              this.sponsor.status = true
              this.sponsor.name = response.data.user.name
              this.sponsor.username = response.data.user.username
              this.sponsor.avatar = response.data.user.avatar

              this.entity.name = response.data.entity.name
              this.entity.url = response.data.entity.url
              this.entity.description = response.data.entity.description
              this.entity.site = response.data.entity.site
              this.entity.email = response.data.entity.email
              this.entity.telephone = response.data.entity.telephone
              this.entity.logo = response.data.entity.logo

              localStorage.sponsor = JSON.stringify(response.data.user)

              this.$router.push('/register/' + response.data.user.username)
            }
          })
      }
    },
    registerSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { name, birthday, taxid, email, cellphone, zipcode, address, number, district, city, state, username, password } = this.user;
        if (name && birthday && taxid && email && cellphone && zipcode && address && number && district && city && state && username && password) {
          this.loading.register = true
          api
            .post('user', {
              sponsor: this.user.sponsor,
              name: this.user.name,
              birthday: this.user.birthday,
              taxid: this.user.taxid,
              email: this.user.email,
              cellphone: this.user.cellphone,
              zipcode: this.user.zipcode,
              address: this.user.address,
              number: this.user.number,
              complement: this.user.complement,
              district: this.user.district,
              city: this.user.city,
              state: this.user.state,
              username: this.user.username,
              password: this.user.password,
            })
            .then(response => {
              if (response.data.status=='success') {
                this.user.sponsor = ''
                this.user.name = ''
                this.user.birthday  = '';
                this.user.taxid = ''
                this.user.email = ''
                this.user.cellphone = ''
                this.user.zipcode = ''
                this.user.address = ''
                this.user.number = ''
                this.user.complement = ''
                this.user.district = ''
                this.user.city = ''
                this.user.state = ''
                this.user.username = ''
                this.user.password = ''
                this.$v.$reset()

                this.alert.type = 'alert-success'
                this.alert.message = response.data.message

                this.success = true
              } else {
                this.alert.type = 'alert-danger'
                this.alert.message = response.data.message
              }

              this.loading.register = false
            })
            .catch(error => {
              if (error) {
                this.loading.register = false
              }
            })
            .finally(() => {
              this.loading.register = false
            })
        }

      }
    },
		searchCep () {
			if (this.user.zipcode.length == 9) {
				axios.get('https://viacep.com.br/ws/' + this.user.zipcode + '/json')
				.then(response => {
          this.user.address = response.data.logradouro
          this.user.district = response.data.bairro
          this.user.city = response.data.localidade
          this.user.state = response.data.uf
        })
			}
		}
  },
  mounted() {
    if (this.$route.params.sponsor) {
      this.user.sponsor = this.$route.params.sponsor

      api
        .post('user/sponsor', {
          username: this.user.sponsor
        })
        .then(response => {
          if (response.data.status=='error') {
            this.sponsor.status = false
            this.sponsor.name = ''
            this.sponsor.avatar = ''
            this.$router.push('/register')
          } else {
            this.sponsor.status = true
            this.sponsor.name = response.data.user.name
            this.sponsor.username = response.data.user.username
            this.sponsor.avatar = response.data.user.avatar

            this.entity.name = response.data.entity.name
            this.entity.url = response.data.entity.url
            this.entity.description = response.data.entity.description
            this.entity.site = response.data.entity.site
            this.entity.email = response.data.entity.email
            this.entity.telephone = response.data.entity.telephone
            this.entity.logo = response.data.entity.logo

            localStorage.sponsor = JSON.stringify(response.data.user)
          }
        })
    }

    if (!this.user.sponsor) {
      if (localStorage.sponsor) {
        this.user.sponsor = JSON.parse(localStorage.sponsor).username
        this.getSponsor()
      }
    }

    this.getEntities()
    this.getCountries()
  },
  watch: {
    'user.country': function (value) {
      console.log(value);
      this.country = this.countries[value]
      this.country.mask = '+' + this.countries[value].code + '.###############'
    }
  }
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img height="79px" src="@/assets/images/logo.png">
            </router-link>
          </div>
          <div class="pt-5">
            <div v-if="success" class="text-center pt-4 pb-4">
              <h5>{{ t('Congratulations!') }}<br>{{ t('Your account has been successfully created.') }}</h5>
            </div>
            <div v-else>
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <b-form @submit.prevent="registerSubmit">
                <b-form-group v-if="user.sponsor" id="sponsor-group" :label="t('Ambassador')" label-for="sponsor">
                  <b-form-input id="sponsor" v-model="user.sponsor" type="text" value="" :class="{ 'is-invalid': $v.user.sponsor.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" v-on:blur="getSponsor()"></b-form-input>
                  <div v-if="!$v.user.sponsor.required" class="invalid-feedback">{{ t('Ambassador is required.' )}}</div>
                  <div v-if="sponsor.status && sponsor.name" class="d-flex my-2 p-3 bg-soft-dark rounded">
                    <div class="mr-2 align-self-center">
                      <img
                        v-if="sponsor.avatar"
                        class="rounded-circle header-profile-user"
                        :src="sponsor.avatar"
                        alt=""
                      />
                      <img
                        v-else
                        class="rounded-circle header-profile-user"
                        src="@/assets/images/logo-icon.png"
                        alt=""
                      />
                    </div>
                    <div class="flex-fill align-self-center">
                      <span class="text-uppercase">{{ sponsor.name }}</span>
                    </div>
                  </div>
                  <div v-else-if="!sponsor.status && user.sponsor && sponsor.username" class=" my-2 p-3 bg-soft-danger rounded">
                    O Embaixador não foi encontrado.
                  </div>
                </b-form-group>
                <div v-if="user.sponsnor && entity.name" class="text-dark font-size-15 text-left d-flex justify-content-between mb-4">
                  <div class="bg-white p-2" v-if="entity.logo"><img style="max-height:50px;" :src="entity.logo" /></div>
                  <div class="bg-white p-2" v-else><img style="max-height:50px;" src="@/assets/images/logo-icon.png" /></div>
                  <span class="flex-fill px-3 align-self-center">{{ entity.name }}</span>
                </div>
                <hr v-if="user.sponsor" class="my-4">
                <b-form-group id="name-group" :label="t('Name')" label-for="name">
                  <b-form-input id="name" v-model="user.name" type="text" :class="{ 'is-invalid': $v.user.name.$error }"></b-form-input>
                  <div v-if="!$v.user.name.required" class="invalid-feedback">{{ t('Name is required.') }}</div>
                </b-form-group>
                <b-form-group id="birthday-group" :label="t('Data de Nascimento')" label-for="birthday">
                  <b-form-input id="birthday" v-model="user.birthday" v-mask="'##/##/####'" type="text" :class="{ 'is-invalid': $v.user.birthday.$error }"></b-form-input>
                  <div v-if="$v.user.birthday.$error" class="invalid-feedback">
                    <span v-if="!$v.user.birthday.required">{{ t('Birthday is required.') }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="taxid-group" :label="t('Taxid')" label-for="taxid">
                  <b-form-input id="taxid" v-model="user.taxid" type="text" :class="{ 'is-invalid': $v.user.taxid.$error }" v-mask="'###.###.###-##'"></b-form-input>
                  <div v-if="!$v.user.taxid.required" class="invalid-feedback">{{ t('Taxid is required.') }}</div>
                  <div v-if="!$v.user.taxid.checkCpf" class="invalid-feedback">{{ t('The CPF entered is invalid.') }}</div>
                </b-form-group>
                <b-form-group id="email-group" :label="t('E-mail')" label-for="email">
                  <b-form-input id="email" v-model="user.email" type="email" :class="{ 'is-invalid': $v.user.email.$error }"></b-form-input>
                  <div v-if="$v.user.email.$error" class="invalid-feedback">
                    <span v-if="!$v.user.email.required">{{ t('E-mail is required.') }}</span>
                    <span v-if="!$v.user.email.email">{{ t('This value should be a valid e-mail.') }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="cellphone-group" :label="t('Cellphone')" label-for="cellphone">
                  <!-- <select v-model="user.country" class="custom-select mb-1">
                    <option v-for="(option,index) in countries" :key="index" :value="option.abbr">{{ option.abbr + ' ' + option.name + ' +' + option.code }}</option>
                  </select> -->
                  <b-input-group :prepend="country.abbr + ' +' + country.code" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="cellphone" v-model="user.cellphone" type="text" :class="{ 'is-invalid': $v.user.cellphone.$error }" v-mask="country.mask"></b-form-input>
                  </b-input-group>
                  <div v-if="!$v.user.cellphone.required" class="invalid-feedback">{{ t('Cellphone is required.') }}</div>
                </b-form-group>

                <hr class="my-4">

                <b-form-group id="zipcode-group" :label="t('Zipcode')" label-for="zipcode">
                  <b-form-input id="zipcode" v-model="user.zipcode" type="text" :class="{ 'is-invalid': $v.user.zipcode.$error }" v-mask="'#####-###'" @keyup="searchCep()"></b-form-input>
                  <div v-if="!$v.user.zipcode.required" class="invalid-feedback">{{ t('Zipcode is required.') }}</div>
                </b-form-group>
                <b-form-group id="address-group" :label="t('Address')" label-for="address">
                  <b-form-input id="address" v-model="user.address" type="text" :class="{ 'is-invalid': $v.user.address.$error }"></b-form-input>
                  <div v-if="!$v.user.address.required" class="invalid-feedback">{{ t('Address is required.') }}</div>
                </b-form-group>
                <b-form-group id="number-group" :label="t('Number')" label-for="number">
                  <b-form-input id="number" v-model="user.number" type="text" :class="{ 'is-invalid': $v.user.number.$error }"></b-form-input>
                  <div v-if="!$v.user.number.required" class="invalid-feedback">{{ t('Number is required.') }}</div>
                </b-form-group>
                <b-form-group id="complement-group" :label="t('Complement')" label-for="complement">
                  <b-form-input id="complement" v-model="user.complement" type="text" :class="{ 'is-invalid': $v.user.complement.$error }"></b-form-input>
                  <div v-if="!$v.user.complement.required" class="invalid-feedback">{{ t('Complement is required.') }}</div>
                </b-form-group>
                <b-form-group id="district-group" :label="t('District')" label-for="district">
                  <b-form-input id="district" v-model="user.district" type="text" :class="{ 'is-invalid': $v.user.district.$error }"></b-form-input>
                  <div v-if="!$v.user.district.required" class="invalid-feedback">{{ t('District is required.') }}</div>
                </b-form-group>
                <b-form-group id="city-group" :label="t('City')" label-for="city">
                  <b-form-input id="city" v-model="user.city" type="text" :class="{ 'is-invalid': $v.user.city.$error }"></b-form-input>
                  <div v-if="!$v.user.city.required" class="invalid-feedback">{{ t('City is required.') }}</div>
                </b-form-group>
                <b-form-group id="state-group" :label="t('State')" label-for="state">
                  <b-form-input id="state" v-model="user.state" type="text" :class="{ 'is-invalid': $v.user.state.$error }" v-mask="'XX'"></b-form-input>
                  <div v-if="!$v.user.state.required" class="invalid-feedback">{{ t('State is required.') }}</div>
                </b-form-group>

                <hr class="my-4">

                <b-form-group id="username-group" :label="t('Username/Nickname')" label-for="username">
                  <b-form-input id="username" v-model="user.username" type="text" :class="{ 'is-invalid': $v.user.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"></b-form-input>
                  <div v-if="!$v.user.username.required" class="invalid-feedback">{{ t('Username is required.') }}</div>
                  <div v-if="!$v.user.username.checkUsername" class="invalid-feedback">{{ t('Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed.') }}</div>
                </b-form-group>
                <b-form-group id="password-group" :label="t('Password')" label-for="password">
                  <Password v-model="user.password" :v="$v.user.password"></Password>
                </b-form-group>

                <div class="text-uppercase font-size-13 px-2">
                  <label class="d-flex mb-2">
                    <div class="mr-2"><input type="checkbox" v-model="user.terms.geral" class="align-top mt-1" :class="{ 'is-invalid': $v.user.terms.geral.$error }"></div>
                    <div>
                      Aceito os Termos Gerais do Regulamento
                      <a target="_blank" href="https://influenciadorespelobem.org.br/wp-content/uploads/2022/02/REGULAMENTO-GERAL.pdf">[LER]</a>
                    </div>
                  </label>
                  <label class="d-flex mb-2">
                    <div class="mr-2"><input type="checkbox" v-model="user.terms.auction" class="align-top mt-1" :class="{ 'is-invalid': $v.user.terms.auction.$error }"></div>
                    <div>
                      Aceito os Termos do Leilão do Bem
                      <a target="_blank" href="https://influenciadorespelobem.org.br/wp-content/uploads/2022/02/REGULAMENTO-LEILAO-DO-BEM.pdf">[LER]</a>
                    </div>
                  </label>
                  <label class="d-flex mb-2">
                    <div class="mr-2"><input type="checkbox" v-model="user.terms.bonus" class="align-top mt-1" :class="{ 'is-invalid': $v.user.terms.bonus.$error }"></div>
                    <div>
                      Aceito as Regras de Beneficios do Cash Back e/ou Bônus
                      <a target="_blank" href="https://influenciadorespelobem.org.br/wp-content/uploads/2022/02/REGRA-DE-BENEFICIOS-do-CASH-BACK-e-ou-BONUS.pdf">[LER]</a>
                    </div>
                  </label>
                  <label class="d-flex mb-2">
                    <div class="mr-2"><input type="checkbox" v-model="user.terms.condition" class="align-top mt-1" :class="{ 'is-invalid': $v.user.terms.condition.$error }"></div>
                    <div>
                      Aceito os Termo de Uso e Condições do Portal e do Aplicativo
                      <a target="_blank" href="https://influenciadorespelobem.org.br/wp-content/uploads/2022/02/TERMO-DE-USO-E-CONDICOES-DO-PORTAL-E-DO-APLICATIVO.pdf">[LER]</a>
                    </div>
                  </label>
                  <label class="d-flex mb-2">
                    <div class="mr-2"><input type="checkbox" v-model="user.terms.policy" class="align-top mt-1" :class="{ 'is-invalid': $v.user.terms.policy.$error }"></div>
                    <div>
                      Aceito a Política de Privacidade e Segurança do Portal e do Aplicativo
                      <a target="_blank" href="https://influenciadorespelobem.org.br/wp-content/uploads/2022/02/POLITICA-DE-PRIVACIDADE-E-SEGURANCA-DO-PORTAL-E-DO-APLICATIVO.pdf">[LER]</a>
                    </div>
                  </label>
                </div>
                <div class="mt-4">
                  <b-button :disabled="loading.register == true || !user.terms.geral || !user.terms.auction || !user.terms.bonus || !user.terms.condition || !user.terms.policy" type="submit" variant="danger" class="btn-lg btn-block">
                    {{ t('Register') }}
                    <b-spinner v-if="loading.register" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
          <div class="mt-4 text-center">
            <p v-if="success" class="text-dark">
              <router-link tag="a" to="/login" class="btn btn-danger">{{ t('Click here to login') }}</router-link>
            </p>
            <p v-else class="text-dark">
              {{ t('Already have an account?') }}
              <router-link tag="a" to="/login" class="font-weight-medium text-danger">{{ t('Login') }}</router-link>
            </p>
            <Languages type="dropup"></Languages>
            <hr class="mx-5">
            <p class="font-size-12 text-uppercase text-muted">
              Tecnologia por <a target="_blank" href="http://m2n.com.br/">M2N</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
