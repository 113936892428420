import { cpf } from 'cpf-cnpj-validator';

export function checkUsername(username) {
  var words = ["gmail", "hotmail", "yahoo", "msn", "live", "com", "br"];
  var check = true;

  words.map(word => {
    if (username.match(word)) {
      check = false;
    }
  });

  return check;
}

export function checkCpf(taxid) {
  var check = true;
  if (!cpf.isValid(taxid)) {
    check = false;
  }

  return check;
}